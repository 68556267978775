import * as application from "./application"
import * as database from "./database"
import * as mangrove from "./mangrove"
import * as vega from "./vega"

export default {
  ...application,
  ...database,
  ...mangrove,
  ...vega
}