import * as actions from "../actions"
import asyncActions from "../async-actions"

import { catchMessage, getUrl } from "./mangrove"

import { isJsonString, makeRequest } from "../../lib/utils"

import moment from "moment"
import "moment-timezone"

export function fetchLocalFile(path, type) {
  return async function (dispatch) {
    return await fetch(path)
      .then(response => type === "json" ? response.json() : response.text())
      .then(content => {
        return content
      })
      .catch(function (error) {
        catchMessage({ exception: error }, dispatch, "fetchLocalFile")
      })
  }
}

export function getAvailableTimes() {
  const startTime = moment.utc().startOf("day").toISOString()
  const endTime = moment.utc().startOf("day").add(1, "days").toISOString()

  const parameters = {
    startTime: startTime,
    endTime: endTime,
    forecastCount: 1,
    layers: "gp.nowcast",
    importFromExternalDataSource: false
  }

  return async function (dispatch) {
    const url = `https://rainguru.hkvservices.nl/FewsWebServices/wms?request=GetCapabilities&format=application/json&startForecastTime=${parameters.startTime}&endForecastTime=${parameters.endTime}&forecastCount=${parameters.forecastCount}&layers=${parameters.layers}&importFromExternalDataSource=${parameters.importFromExternalDataSource}`

    return await fetch(url)
      .then((response) => response.json())
      .then((json) => {
        let times = []

        if (!json || json && json.hasOwnProperty("exception")) {
          catchMessage(json, dispatch, "getAvailableTimes")
        } else {
          if (json) {
            times = json.layers.find(l => l.times && l.times.length > 0).times
          }

          if (times.length > 0) {
            times.shift()
          }
        }

        return times
      })
      .catch(function (error) {
        catchMessage({ exception: error }, dispatch, "getAvailableTimes")
      })
  }
}

export function getLocationInfo(parameters) {
  const functionName = "mangrove.googlegeocode.GetGeocode"
  const url = getUrl(functionName, parameters)

  return async function (dispatch) {
    return await fetch(url)
      .then((response) => response.json())
      .then((json) => {
        let data

        if (!json || json && json.hasOwnProperty("exception")) {
          catchMessage(json, dispatch, "getLocationInfo")
        } else {
          if (json.Status === "OK" && json.Result.length > 0) {
            const containsStreetAddress = json.Result.find(result => result.Type.find(type => type === "street_address"))
            const containsCountry = json.Result.find(result => result.Type.find(type => type === "country"))
            // Als in de data een "street_address" staat, gebruiken we deze, anders gewoon het eerste result

            if (containsCountry && containsCountry.Formatted_address === "Nederland") {
              if (containsStreetAddress) {
                data = containsStreetAddress
              } else {
                data = json.Result[0]
              }
            } else {
              if (json.Result[0].Formatted_address.includes("Nederland")) {
                data = json.Result[0]
              } else {
                data = null
              }
            }
          }
        }

        return data
      })
      .catch(function (error) {
        catchMessage({ exception: error }, dispatch, "getLocationInfo")
      })
  }
}

export function getLocationPrecipitation(parameters) {
  const url = `https://rainguru.hkvservices.nl/FewsWebServices/rest/fewspiservice/v1/timeseries/grid?documentFormat=PI_JSON&layers=${parameters.layers}&x=${parameters.x}&y=${parameters.y}&bbox=${parameters.bbox}&startTime=${parameters.startTime}&endTime=${parameters.endTime}&importFromExternalDataSource=false`

  return async function (dispatch) {
    return await fetch(url)
      .then((response) => response.json())
      .then((json) => {
        if (!json || json && json.hasOwnProperty("exception")) {
          catchMessage(json, dispatch, "getLocationPrecipitation")
        } else {
          const formattedEvents = []

          if (json && json.timeSeries) {
            const { events } = json.timeSeries[0]

            events.map((e, i) => {
              formattedEvents.splice(i, 0, {
                date: moment.utc(`${e.date} ${e.time}`, "YYYY-MM-DD hh:mm:ss").tz("Europe/Amsterdam"),
                value: parseFloat(e.value),
                quantile: 0.5
              })
            })
          }
          dispatch(setPrecipitationInState(formattedEvents))

          return formattedEvents
        }
      })
      .catch(function (error) {
        catchMessage({ exception: error }, dispatch, "getLocationPrecipitation")
      })
  }
}

export function setPrecipitationInState(precipitation) {
  return {
    type: actions.SET_PRECIPITATION,
    precipitation
  }
}
