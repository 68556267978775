import * as actions from "../actions"
import asyncActions from "../async-actions"

import {
  catchMessage,
  getUrl
} from "./mangrove"

import moment from "moment"

export function getAppInfo(appname, parameters) {
  const functionName = `${appname}.appfunctions.getAppInfo`
  const url = getUrl(functionName, parameters)

  return async function (dispatch) {
    return await fetch(url)
      .then(response => response.json())
      .then(json => {
        dispatch(setAppInfoInState(json))

        if (!json || json && json.exception) {
          catchMessage(json, dispatch, "getAppInfo")
          return json && (json.type === "success" || json.type === "info") ? json : null
        } else {
          return json
        }
      })
      .catch(function (error) {
        catchMessage({ exception: error }, dispatch, "getAppInfo")
      })
  }
}

export function loaderPlus(amount) {
  return async function (dispatch) {
    dispatch({ type: actions.LOADING_STATE, loading: amount === 0 ? 0 : (!amount ? 1 : amount) }) // 0 = 0, undefined = 1, >0 = amount
  }
}

export function loaderMin(amount) {
  return async function (dispatch) {
    dispatch({ type: actions.LOADING_STATE, loading: amount === 0 ? 0 : (!amount ? -1 : -amount) })
  }
}

export function locationLoaderPlus(amount) {
  return async function (dispatch) {
    dispatch({ type: actions.LOCATION_LOADING_STATE, loading: amount === 0 ? 0 : (!amount ? 1 : amount) }) // 0 = 0, undefined = 1, >0 = amount
  }
}

export function locationLoaderMin(amount) {
  return async function (dispatch) {
    dispatch({ type: actions.LOCATION_LOADING_STATE, loading: amount === 0 ? 0 : (!amount ? -1 : -amount) })
  }
}

export function loaderTimeDimension(bool) {
  return async function (dispatch) {
    dispatch({ type: actions.TIMEDIMENSION_LOADING_STATE, loading: bool })
  }
}

export function setError(error) {
  return async function (dispatch) {
    // Unieke key hangen aan error
    if (error) { error.key = moment().valueOf() }
    dispatch({ type: actions.SET_ERROR, error: error })
  }
}

export function setAppInfoInState(appinfo) {
  return {
    type: actions.SET_APP_INFO,
    appinfo
  }
}